<template>  
  <v-card color="transparent" rounded="lg" elevation="0" style="border: 1px solid var(--v-primary-base)">
    <v-card-text>
      <v-row>
        <v-col
          align="center"
          cols="12"
          md="5"
        >
          <h3 class="text-h6">{{ items[0].title }}</h3>
          <h6 class="text-h2">{{ items[0].amount }}</h6>
        </v-col>
        <v-col align="center" md="2">
          <v-divider vertical />
        </v-col>
        <v-col align="center" cols="12" md="5">
          <h3 class="text-h6">{{ items[1].title }}</h3>
          <h6 class="text-h2">{{ items[1].amount }}</h6>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    props: {
      items: Array
    },
  }
</script>